// Breakpoints
// $breakpoints: (
//     xs : 576px,
//     sm : 768px,
//     md : 992px,
//     lg : 1200px,
//     xl : 1920px
// );


$navbar-padding-y: 20px;
$navbar-nav-link-padding-x: 25px;

$dark-text: #111;
$white-text: #fff;
$primary-color: #ECB211;



// $navbar-dark-color: white;
// $navbar-dark-disabled-color:        rgba($white, .25);
// $navbar-dark-toggler-icon-bg:       url("data:image/svg+xml,<svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 30 30'><path stroke='#{$navbar-dark-color}' stroke-linecap='round' stroke-miterlimit='10' stroke-width='2' d='M4 7h22M4 15h22M4 23h22'/></svg>");
// $navbar-dark-toggler-border-color:  rgba($white, .1);
