.hero{
    background: url('../../img/bg/hero2.png');
    background-repeat: no-repeat;
    background-position: center top;
    background-color: #000;
    width: 100%;
    min-height: 100vh;
    .dark-overlay{
        width: 100%;
        min-height: 100vh;
        background: rgb(0,0,0);
        background: radial-gradient(ellipse at right top, rgba(100,100,100, 0.2) 0%, rgba(0,0,0, 75%) 80%);
        color: white;
        .hero-row{
            padding: 300px 0 100px;
        }
    }
    .hero-img{
        margin-bottom: 100px;
    }
    .hero-text{
        h1{
            font-size: 48px;
            font-weight: bold;
            margin-bottom: 25px;
        }
        p{
            font-size: 24px;
            font-weight: lighter;
            margin-bottom: 50px;
            
        }
    }
}
.hero-order{
    background: url('../../img/bg/hero3.png');
    background-repeat: no-repeat;
    background-color: #000;
    .dark-overlay{
        background: linear-gradient(rgba(0,0,0, 0) 10%, #000 100%);
    }
}

.hero-products{
    background: url('../../img/bg/hero3.png');
    background-repeat: no-repeat;
    background-position: center center;
    background-color: #000;
    width: 100%;
    height: 40vh;
    box-shadow: 0 8px 8px rgba(0,0,0, 0.15);
    
    h1{
        font-size: 52px;
        color: #fff;
        font-weight: bold;
        padding-top: 3vh;
        text-shadow: 5px 5px 5px rgba(0,0,0, 0.25);
    }
}


@include media-breakpoint-down(md) {
    .hero-img{
        width: 90%;
    }
    .hero-text{
        h1{
            font-size: 32px !important;
        }
        p{
            font-size: 18px !important;
        }
    }
    .hero-row{
        padding: 200px 0 100px !important;
    }

}