.sec-1{
    padding: 200px 0;
    .icons{
        height: 170px;
    }
    p{
        font-weight: lighter;
        font-size: 18px;
        text-align: justify;
    }
    h2,h3{
        font-weight: bold;
    }
    h2{
        font-size: 48px;
        margin-bottom: 30px;
    }
    h3{
        font-size: 28px;
        margin: 30px 0 0;
        padding: 0 10px;
        text-align: center;
    }
    .sec-1-img{
        max-height: 500px;
    }
    .sec-1-logo{
        width: 100%;
        padding: 50px 0;
    }
    a{
        transition: .25s;
        width: 90%;
        &:hover{
            transform: scale(0.9);
        }
    }
    .col-devider{
        border-right: 3px solid rgba($color: $primary-color, $alpha: 1) !important; 
        &:last-child{
            border: none !important;
        }
    }
    .weights-specs{
        .row{
            padding: 50px 25px 0;
        }
        img{
            // max-height: 300px;
            width: 100%;
        }
        .btn{
            width: 200px;
        }
    }
    .underline-text{
        border-bottom: 3px solid rgba($color: $primary-color, $alpha: 1);
    }
}

.sec-2{
    width: 100%;
    background-image: url('../../img/bg/hero2.png');
    background-repeat: no-repeat;
    background-position: top center;
    background-size: cover;
    .dark-overlay{
        width: 100%;
        height: 100%;
        background: rgb(0,0,0);
        background: linear-gradient(rgba(0,0,0, 0) 10%, #000 100%);
    }
}

.contact-info{
    // margin-top: 40px;
    img{
        height: 40px;
        padding-right: 0px !important;
    }
    th{
        padding: 20px 0;
        padding-right: 40px;
        display: flex;
        justify-content: center;
    }
    a{
        color: $primary-color;
        text-decoration: none;
    }
}

.hero-typed{
    width: 100%;

}
.about-hero{
    font-size: 2rem;
    p{
        margin-bottom: 0px !important;
        font-size: 32px !important;
    }
    .typed-bold{
        font-size: 5rem;
        font-family: 'Archivo Black', sans-serif;
        color: $primary-color;
    }
    .after-typed{
        opacity: 0;
        font-size: 22px !important;
        font-weight: lighter !important;
        margin-top: 50px;
        transition: 1s;
        transform: translateY(50px);
    }
    .text-appear{
        opacity: 1;
        transform: translateY(1);
    }
}

.contact-sec{
    padding-top: 200px;
    color: $dark-text !important;
    p{
        font-weight: lighter;
    }
    .contact-row-1{
        background-color: white;
        min-height: 400px;
        padding: 85px 100px;
    }
    
}


.products{
    padding-top: 50px;
    // background-color: red;
    .products-item{
        padding-bottom: 50px;
        h2{
            font-size: 32px;
            font-weight: bold;
        }
        h3{
            font-weight: bold;
            font-size: 24px;
        }
    }
}
.row-devider{
    border-bottom: 1px solid rgba($color: #000, $alpha: 0.1) !important; 
        &:last-child{
            border: none !important;
        }
    
}
.terms{
    p{
        text-align: justify;
    }
}

@include media-breakpoint-down(md) {
    .contact-row-1{
        padding: 85px 15px !important;
    }
    .contact-sec{
        padding-top: 200px !important;
    }
    .sec-1{
        h2{
            font-size: 32px;
        }
        h3{
            font-size: 26px;
        }
    }
    .sec-1-img{
        width: 90%;
    }
    .sec-2{
        .col{
            padding: 0 10px !important;
        }
        h2{
            font-size: 32px !important; 
        }
        img{
            width: 90% !important;
        }
    }
   
    .hero-typed{
        p{
            font-size: 20px !important;
        }
        #text-appear{
            font-size: 20px !important; 
        }
    }
}

@include media-breakpoint-down(sm) {
    .typed-bold{
        font-size: 3rem !important;
    }
}

.small-text{
    font-size: 12px !important;
}

.main-color{
    color: $primary-color;
}