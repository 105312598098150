footer{
    background-color: #000;
    padding: 40px;

    p, a{
        font-size: 12px;
        color: rgba($color: #fff, $alpha: 0.5);
        text-align: center;
        text-decoration: none;
        transition: .25s;
    }
    p{
        margin: 0;
        padding: 0 !important;
    }
    a:hover, p:hover{
        color: #ECB211;
    }
}

.col-devider{
    border-right: 1px solid rgba($color: white, $alpha: 0.6) !important; 
    &:last-child{
        border: none !important;
    }

}


// @include media-breakpoint-down(md) {
//     .col-devider{
//         border: none;
//     }
// }

@media (max-width: 767.98px) {
    .col-devider{
        border: none !important;
    }
    p{
        margin: 12px 0  !important;
    }
}