
// import fonts
@import url('https://fonts.googleapis.com/css2?family=Archivo+Black&family=Sora:wght@300;400;700&display=swap');


@import "./abstracts/variables";

// import bootstrap
@import "~bootstrap/scss/bootstrap";

// @use "~bootstrap/scss/variables";
// @import 'abstracts/mixins';
// @use "~bootstrap/scss/root";

// import partials

@import "partials/navbar";
@import "partials/hero";
@import "partials/footer";
@import "partials/sections";
@import "partials/form";
@import "partials/button";




*{
    font-family: 'Sora', sans-serif;
}