.contact-form{
    margin-top: 35px;
    textarea{
        min-height: 150px;
    }
    .required::before{
        content: '*';
        color: #ff1770;
        // color: $primary-color;
        display: inline;
    }
}

.arrow-before{
    display: flex;
    align-items: center;
    color: $primary-color;
    text-decoration: none;
    padding: 10px 0;
    &:hover{
        color: $primary-color;
        &::before{
            transform: translateX(-10px);
        }
    }
    &::before{
        content: '';
        background-image: url('../../img/icons/left-arrow.svg');
        background-repeat: no-repeat;
        display: block; // NE POZABIT TEGA!!
        width: 35px;
        height: 15px;
        margin-right:5px;
        transition: .25s;
    }
}

.rail-select {
    position: absolute;
    width: 150px;
    
    select {
      border: solid 1px grey;
      border-radius: 3px;
      font-size: 16px;
      color: gray;
      height: 42px;
      appearance: none;
      -moz-appearance: none;
      -webkit-appearance: none;
      
      &:focus {
        outline: none;
        box-shadow: none;
        border: solid 1px grey;
      }
    }
    
    .select-side {
      &:before {
        border-left: solid 1px lightgrey;
        content : "";
        position: absolute;
        left    : 0;
        bottom  : 0;
        height  : 100%;
        width   : 1px;  /* or 100px */
      }
      
      width: 40px;
      position: absolute;
      top: 0px;
      background-color: #F3F9FE;
      height: 100%;
      left: 120px;
      border-radius: 0px 3px 3px 0px;
      border-right: solid 1px gray;
      border-top: solid 1px gray;
      border-bottom: solid 1px gray;
      pointer-events:none;
    
      i {
        &.blue {
          color: #4E7AF0;
        }
        
        left: 30%;
        top: 12px;
      }
    }
  }

.form-line{
    border-top: 1px solid rgba(0,0,0, .2) !important;
}