.navbar{
    transition: .25s;
    .nav-link{
        font-size: 16px;
        color: $white-text !important;
        &:hover{
            color: $white-text !important;
        }
    }
    .last-nav{
        padding-right: 0 !important;
    }
    .navbar-brand{
        position: relative;
        // z-index: 2000;
        height: 84px;
        img{
            position: absolute;
            top: 0;
            left: 0;
            transition: .25s;
        }
        .brand-light{
            opacity: 1;
        }
        .brand-dark{
            opacity: 0;
        }
    }

}

.sticky{
    // height: 115px;
    background-color: rgba($color: #fff, $alpha: 1);
    box-shadow: 0px 3px 6px rgba($color: #000000, $alpha: 0.15);
    .nav-link{
        color: $dark-text !important;
        &:hover{
            color: $dark-text !important;
        }
    }
    
    .active{
        color: $dark-text !important;
    }
    .brand-light{
        opacity: 0 !important;
    }
    .brand-dark{
        opacity: 1 !important;
    }
}


#sticky-trigger{
    position: absolute;
    top: 80px;
    left: 0;
}


.hover-underline-animation {
    display: inline-block;
    position: relative;
  }
  
  .hover-underline-animation:after {
    content: '';
    position: absolute;
    width: 100%;
    transform: scaleX(0);
    height: 2px;
    bottom: 0;
    left: 0;
    background-color: #ECB211;
    transform-origin: bottom right;
    transition: transform 0.25s ease-out, background-color 0.25s;
    
  }
  .sticky{
    .hover-underline-animation:after{
        background-color: #ECB211 !important;
    }
}
  
.nav-link:hover .hover-underline-animation:after {
transform: scaleX(1);
transform-origin: bottom left;
}
.active .hover-underline-animation:after {
transform: scaleX(1);
transform-origin: bottom left;
}

.show{
    .nav-link{
        color: $dark-text !important;
        &:hover{
            color: $dark-text !important;
        }
    }
    
    .active{
        color: $dark-text !important;
    }
}

