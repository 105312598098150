.btn{
    width: fit-content;
    padding: 10px 35px;
    background-color: rgba($color: #000000, $alpha: 0);
    border: 2px solid $primary-color;
    border-radius: 30px;
    font-size: 16px;
    color: $primary-color;

    
    &:hover, &:focus{
        background-color: $primary-color;
        color: $white-text;
        border: 2px solid $primary-color;
    }
    
}



.btn-secondary{
    border-color: $white-text;
    color: $white-text;
}


.btn:focus,.btn:active {
    outline: none !important;
    box-shadow: none;
}

.btn-add{
    width: 38px !important;
    height: 38px !important;
    font-size: 32px;
    transition: .25s;

}

.plus {
    --b:2px; /* the thickness */
    width:35px; /* the size */
    aspect-ratio:1/1;
    border:10px solid #fff; /* the outer space */
    background:
        conic-gradient(from 90deg at var(--b) var(--b),#fff 90deg,#555 0) 
        calc(100% + var(--b)/2) calc(100% + var(--b)/2)/
        calc(50%  + var(--b))   calc(50%  + var(--b));
    display:inline-block;
    border-radius:20%;
    transition: .25s;
    &:hover{
        width: 45px !important;
        height: 45px !important;
    }
}

.btn-remove{
    border: none;
    outline: none;
    color: #ff1770;
    padding: 0;
    background: none;
    transition: .25s;
    font-size: 13px !important;
    &:hover{
        transform: translateX(15px);
    }
}